<template>
  <div id="assets" v-if="assets">
    <b-row>
      <b-col cols="12">
        <b-card class="card-custom gutter-b" no-body>
          <!--ddmenu::begin-->
          <div class="card-header">
            <div class="card-title d-flex flex-wrap">
              <!--ddtypes::begin-->
              <div class="mr-5">
                <div
                  class="input-group input-group-sm input-group-solid"
                  style="max-width: 175px"
                >
                  <input
                    type="text"
                    class="form-control"
                    id="search"
                    v-model="search"
                    :placeholder="$t('tables.search') + ' ...'"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <span class="svg-icon">
                        <!--begin::Svg Icon | path:/metronic/theme/html/demo9/dist/assets/media/svg/icons/General/Search.svg-->
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M14.293 16.707a1 1 0 011.414-1.414l4 4a1 1 0 01-1.414 1.414l-4-4z" fill="#000" fill-rule="nonzero" opacity=".3"/><path d="M11 16a5 5 0 100-10 5 5 0 000 10zm0 2a7 7 0 110-14 7 7 0 010 14z" fill="#000" fill-rule="nonzero"/></g></svg>
                        <!--end::Svg Icon-->
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <b-dropdown
                id="ddown-types"
                :text="
                  activeFilters.type.length == 0
                    ? $t(`assets.titles.types`)
                    : $t(`assets.titles.typesFiltered`, {
                        total: activeFilters.type.length
                      })
                "
                class="mr-6"
              >
                <div
                  v-for="(element, index) in contentTypes"
                  :key="element.title + index"
                  class="dropdown-item bg-white text-body"
                >
                  <b-form-checkbox
                    @change="filter(element.key, 'type')"
                    :value="element.title"
                    >{{ element.title }}</b-form-checkbox
                  >
                </div>
              </b-dropdown>
              <!--ddtypes::end-->

              <!--ddfolders::begin-->
              <b-dropdown
                id="ddown-families"
                :text="
                  activeFilters.family.length == 0
                    ? $t(`assets.titles.folders`)
                    : $t(`assets.titles.foldersFiltered`, {
                        total: activeFilters.family.length
                      })
                "
                class="mr-6"
              >
                <div
                  v-for="(element, index) in families"
                  :key="element.title + index"
                  class="dropdown-item bg-white text-body mr-6"
                >
                  <b-form-checkbox
                    :checked="activeFilters.family.includes(element.id)"
                    @change="filter(element.id, 'family')"
                    >{{ element.title }}</b-form-checkbox
                  >
                </div>
              </b-dropdown>
              <!--ddfolders::families-->

              <!--ddstatus::begin-->
              <b-dropdown
                id="ddown-status"
                :text="
                  activeFilters.status.length == 0
                    ? $t(`assets.titles.status`)
                    : $t(`assets.titles.statusFiltered`, {
                        total: activeFilters.status.length
                      })
                "
                class="mr-6"
              >
                <div
                  v-for="(element, index) in status"
                  :key="element.title + index"
                  class="dropdown-item bg-white text-body"
                >
                  <b-form-radio
                    v-model="checkRadioStatus"
                    @change="filter(element.title, 'status', 'or')"
                    :value="element.title"
                    name="radio-status"
                    >{{
                      $t(`assets.filters.status.${element.title}`)
                    }}</b-form-radio
                  >
                </div>
                <b-btn
                  class="align-self-center"
                  variant="link"
                  @click="
                    checkRadioStatus = null;
                    activeFilters.status = [];
                  "
                >
                  {{ $t("tables.clear") }}
                </b-btn>
              </b-dropdown>
              <!--ddstatus::end-->

              <!--ddpriority::begin-->
              <b-dropdown
                id="ddown-priority"
                :text="
                  activeFilters.priority.length == 0
                    ? $t(`assets.titles.priority`)
                    : $t(`assets.titles.priorityFiltered`, {
                        total: activeFilters.priority.length
                      })
                "
                class="mr-6"
              >
                <div
                  v-for="(element, index) in priority"
                  :key="element.title + index"
                  class="dropdown-item bg-white text-body"
                >
                  <b-form-radio
                    v-model="checkRadioPriority"
                    @change="filter(element.title, 'priority', 'or')"
                    :value="element.title"
                    name="radio-priority"
                    >{{
                      $t(`assets.filters.priorities.${element.title}`)
                    }}</b-form-radio
                  >
                </div>
                <b-btn
                  class="align-self-center"
                  variant="link"
                  @click="
                    checkRadioPriority = null;
                    activeFilters.priority = [];
                  "
                >
                  {{ $t("tables.clear") }}
                </b-btn>
              </b-dropdown>
              <!--ddpriority::end-->
            </div>
            <!--ddmenu::end-->
          </div>
          <b-card-body class="pt-0">
            <b-table
              class="pt-0 mt-0"
              id="assets-table"
              :items="filtered"
              :fields="fields"
              :per-page="perPage"
              :current-page="currentPage"
              primary-key="assetId"
              :responsive="true"
              show-empty
              :borderless="true"
              :small="true"
              sort-icon-left
            >
              <template #empty> {{ $t("tables.empty_table") }} </template>
              <template #cell(link)="row">
                <div
                  class="symbol symbol-50 symbol-light mr-5"
                  :set="(icon = getAssetIcon(row.item.type))"
                >
                  <div class="symbol-label">
                    <span v-if="icon.class" class="align-self-center">
                      <i :class="`${icon.class} icon-xl`" />
                    </span>
                    <span
                      v-else-if="icon.image"
                      class="svg-icon svg-icon-xl align-self-center"
                    >
                      <simple-svg
                        :src="require(`@/assets/images/icons/${icon.image}`)"
                        class="h-50 w-50"
                      />
                    </span>
                    <span
                      v-else-if="icon.file"
                      class="svg-icon svg-icon-lg align-self-center"
                    >
                      <img src="@/assets/images/files/ted.svg" class="w-25px" />
                    </span>
                  </div>
                </div>
                <div class="d-flex flex-column flex-grow-1 mr-2 my-2">
                  <div
                    href="#"
                    class="font-weight-bold text-dark-75 font-size-lg mb-1"
                  >
                    {{ row.value }}
                  </div>
                </div>
              </template>
              <template #cell(assetCount)="row">
                <i class="la la-star-o text-warning icon-2x" v-if="row.value" />
              </template>
              <template #cell(totalReaded)="row">
                <span
                  v-if="row.value > 0"
                  class="text-success font-weight-bolder"
                >
                  {{ $t("assets.filters.status.readed") }}</span
                >
                <span v-else class="text-danger font-weight-bolder">
                  {{ $t("assets.filters.status.unreaded") }}</span
                >
              </template>
              <template #cell(actions)="row">
                <b-btn
                  v-if="row.item.type === 'excel' || row.item.type === ''"
                  class="btn-icon svg-icon svg-icon-lg align-self-center"
                  variant="light"
                  @click="download(row.item)"
                >
                  <i class="fa fa-download text-success icon-2l"></i>
                </b-btn>
                <b-btn
                  v-else
                  class="btn-icon svg-icon svg-icon-lg align-self-center"
                  variant="light"
                  @click="downloadFile(row.item)"
                >
                  <i class="la la-eye text-success icon-2l" />
                </b-btn>
              </template>
            </b-table>
            <div class="flex-wrap mx-auto" v-if="total > 0">
              <b-pagination
                class="custom-pagination"
                v-model="currentPage"
                :total-rows="total"
                :per-page="perPage"
                aria-controls="assets-table"
                first-number
                last-number
                align="center"
                size="md"
              >
                <template #first-text>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </template>
                <template #prev-text>
                  <i class="ki ki-bold-arrow-back icon-xs"></i>
                </template>
                <template #next-text>
                  <i class="ki ki-bold-arrow-next icon-xs"></i>
                </template>
                <template #last-text>
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <pdf
        :v-if="showPdf"
        :base64="showingAsset"
        :showAsset="showPdf"
        :name="pdfName"
        @asset-closed="showPdf = false"
      />
      <Html
        v-if="showHtml"
        :showAsset="showHtml"
        :code="html.content"
        @asset-closed="showHtml = false"
        :name="title"
      />
      <b-modal
        v-model="showed"
        size="xl"
        id="asset-modal"
        @hidden="showed = false"
        :cancel-disabled="true"
      >
        <Youtube
          v-if="Object.keys(youtube).length > 0"
          :code="youtube.content"
        />
        <Ted v-else-if="Object.keys(ted).length > 0" :code="ted.content" />
        <Vimeo
          v-else-if="Object.keys(vimeo).length > 0"
          :code="vimeo.content"
        />
        <Iframe
          v-else-if="Object.keys(iframe).length > 0"
          :code="iframe.content"
        />
        <OwnVideo
          v-else-if="Object.keys(ownVideo).length > 0"
          :code="ownVideo.content"
        />
        <ImageAsset
          v-else-if="Object.keys(image).length > 0"
          :code="image.content"
        />

        <template #modal-footer="{ ok }">
          <b-button size="md" pill variant="secondary" @click="ok()">
            OK
          </b-button>
        </template>
      </b-modal>
    </b-row>
  </div>
</template>

<script>
import _ from "lodash";

import Pdf from "@/components/assets/Pdf.component";
import Youtube from "@/components/assets/Youtube.component";
import Ted from "@/components/assets/Ted.component";
import Vimeo from "@/components/assets/Vimeo.component";
import Iframe from "@/components/assets/Iframe.component";
import Html from "@/components/assets/Html.component";
import ImageAsset from "@/components/assets/ImageAsset.component";
import OwnVideo from "@/components/assets/OwnVideo.component";
import { mapGetters, mapActions } from "vuex";
import { SimpleSVG } from "vue-simple-svg";
import { escapeRegExp, getError } from "@/utils/helpers";

export default {
  name: "Assets",
  components: {
    "simple-svg": SimpleSVG,
    Pdf,
    Youtube,
    Ted,
    Vimeo,
    Iframe,
    Html,
    ImageAsset,
    OwnVideo
  },
  data() {
    return {
      status: [{ title: "readed" }, { title: "unreaded" }],
      priority: [{ title: "priority" }, { title: "nopriority" }],
      activeFilters: {
        type: [],
        family: [],
        status: [],
        priority: []
      },
      perPage: 10,
      currentPage: 1,
      showingAsset: "",
      showPdf: false,
      showHtml: false,
      showed: false,
      pdfName: "Kymatio.pdf",
      youtube: {},
      ted: {},
      vimeo: {},
      ownVideo: {},
      iframe: {},
      html: {},
      zip: {},
      image: {},
      link: {},
      xlsx: {},
      pdf: {},
      excel: {},
      checkRadioStatus: null,
      checkRadioPriority: null,
      search: ""
    };
  },
  computed: {
    ...mapGetters("Profile", ["assets", "isProfileLoaded"]),
    contentTypes() {
      return _.map(this.types, (value, key) => {
        return {
          title: this.translateTitleTypes(key),
          key,
          value
        };
      });
    },
    isActive() {
      return params => _.includes(this.activeFilters[params.sub], params.id);
    },
    types() {
      return _.countBy(this.assets, "type");
    },
    families() {
      var families = _.countBy(this.assets, "family");
      var result = [];
      _.each(families, (total, family) => {
        result.push({
          id: family,
          title: this.$t(`assets.buttons.${family}`),
          image: "folder.svg",
          total: total || 0
        });
      });

      return result;
    },
    fields() {
      return [
        {
          key: "link",
          label: this.$t("assets.table.link"),
          sortable: true,
          sortDirection: "desc",
          class:
            "font-weight-bold text-left align-middle d-flex align-items-center flex-wrap mb-3",
          thClass: "h6"
        },
        {
          key: "family",
          label: this.$t("assets.table.family"),
          formatter: value => this.$t(`assets.buttons.${value}`),
          sortByFormatted: true,
          filterByFormatted: true,
          sortable: true,
          sortDirection: "desc",
          class: "font-weight-bold text-left align-middle",
          thClass: "h6"
        },
        {
          key: "assetCount",
          label: this.$t("assets.table.assetCount"),
          sortable: true,
          sortDirection: "desc",
          formatter: value => (parseInt(value) === 1 ? false : true),
          sortByFormatted: true,
          filterByFormatted: true,
          class: "font-weight-bold align-middle",
          thClass: "h6 text-left"
        },
        {
          key: "totalReaded",
          label: this.$t("assets.table.totalReaded"),
          sortable: true,
          sortDirection: "desc",
          formatter: value => (value > 0 ? 1 : 0),
          sortByFormatted: true,
          filterByFormatted: true,
          class: "font-weight-bold align-middle",
          thClass: "h6 text-left"
        },
        {
          key: "actions",
          label: this.$t("assets.table.actions"),
          class: "font-weight-bold text-left align-middle"
        }
      ];
    },
    filtered() {
      var assets = this.assets;
      if (this.search !== "") {
        const regex = new RegExp(
          `\\b${_.deburr(escapeRegExp(this.search))}`,
          "i"
        );
        assets = _.filter(assets, asset => {
          return regex.test(_.deburr(asset.link));
        });
      }
      if (this.activeFilters.family.length > 0) {
        assets = _.filter(assets, asset =>
          _.includes(this.activeFilters.family, asset.family)
        );
      }
      if (this.activeFilters.type.length > 0) {
        assets = _.filter(assets, asset =>
          _.includes(this.activeFilters.type, asset.type)
        );
      }
      if (this.activeFilters.status.length > 0) {
        if (_.includes(this.activeFilters.status, "readed")) {
          assets = _.filter(assets, asset => parseInt(asset.totalReaded) > 0);
        }
        if (_.includes(this.activeFilters.status, "unreaded")) {
          assets = _.filter(assets, asset => parseInt(asset.totalReaded) === 0);
        }
      }
      if (this.activeFilters.priority.length > 0) {
        if (_.includes(this.activeFilters.priority, "nopriority")) {
          assets = _.filter(assets, asset => parseInt(asset.assetCount) === 1);
        }
        if (_.includes(this.activeFilters.priority, "priority")) {
          assets = _.filter(assets, asset => parseInt(asset.assetCount) === 2);
        }
      }

      return assets;
    },
    total() {
      return this.filtered.length;
    },
    ...mapGetters("Constants", ["isConstantsActive"])
  },
  methods: {
    ...mapActions("Profile", ["loadProfile", "reloadAssets"]),
    callGetError(code, params) {
      return getError(code, params);
    },
    translateTitleTypes(type) {
      switch (type) {
        case "pdf":
          return "PDF";
        case "ted_iframe":
          return "TED";
        case "youtube_iframe":
          return "Youtube";
        case "excel":
          return "Excel";
        case "iframe":
          return "Iframe";
        case "image":
          return "Image";
        case "vimeo_iframe":
          return "Vimeo";
        case "own_video":
          return "Video";
        case "xlsx":
          return "XLSX";
        case "zip":
          return "Zip";
        case "html":
          return "Html";
        case "external_link":
          return "Link";
      }
    },
    filter(id, type, cond = "and") {
      if (cond === "and") {
        _.includes(this.activeFilters[type], id)
          ? (this.activeFilters[type] = _.reject(
              this.activeFilters[type],
              el => el === id
            ))
          : this.activeFilters[type].push(id);
      } else {
        _.includes(this.activeFilters[type], id)
          ? (this.activeFilters[type] = _.reject(
              this.activeFilters[type],
              el => el === id
            ))
          : (this.activeFilters[type] = [id]);
      }
    },
    getAssetIcon(type) {
      var result = {};
      switch (type) {
        case "youtube_iframe":
          result.class = "socicon-youtube text-danger";
          break;
        case "ted_iframe":
          result.file = "files/ted.svg";
          break;
        case "vimeo_iframe":
          result.class = "socicon-vimeo text-danger";
          break;
        case "iframe":
          result.image = "file.svg";
          break;
        case "external_link":
          result.class = "fas fa-link";
          break;
        case "excel":
        case "image":
        case "xlsx":
          result.image = "image-svg";
          break;
        case "zip":
          result.image = "zip.svg";
          break;
        case "html":
          result.image = "attachment.svg";
          break;
        case "own_video":
          result.image = "video.svg";
          break;
        default:
          result.class = `fas fa-file-${type}`;
          break;
      }
      return result;
    },
    download(item) {
      const linkSource = `data:application/image;${item.type},${this.base64}`;
      const downloadLink = document.createElement("a");

      downloadLink.href = linkSource;
      downloadLink.download = item.link;
      downloadLink.click();
    },
    downloadFile(asset, automatic = true) {
      this.ted = {};
      this.youtube = {};
      this.vimeo = {};
      this.iframe = {};
      this.html = {};
      this.own_video = {};
      this.axios
        .get(asset.url)
        .then(response => {
          if (automatic) {
            if (
              response.data.records.forward &&
              asset.type !== "youtube_iframe" &&
              asset.type !== "ted_iframe" &&
              asset.type !== "vimeo_iframe" &&
              asset.type !== "excel" &&
              asset.type !== "image" &&
              asset.type !== "xlsx" &&
              asset.type !== "zip" &&
              asset.type !== "html" &&
              asset.type !== "iframe" &&
              asset.type !== "own_video"
            ) {
              let a = document.createElement("a");
              a.href = response.data.records.content;
              a.target = "_blank";
              a.click();
            } else {
              switch (asset.type) {
                case "pdf":
                  this.showingAsset = response.data.records.content;
                  this.showPdf = true;
                  break;
                case "youtube_iframe":
                  this.youtube = response.data.records;
                  this.showed = true;
                  break;
                case "ted_iframe":
                  this.ted = response.data.records;
                  this.showed = true;
                  break;
                case "vimeo_iframe":
                  this.vimeo = response.data.records;
                  this.showed = true;
                  break;
                case "excel":
                  this.excel = response.data.records;
                  this.showed = true;
                  break;
                case "image":
                  this.image = response.data.records;
                  this.showed = true;
                  break;
                case "xlsx":
                  this.xlsx = response.data.records;
                  this.showed = true;
                  break;
                case "zip":
                  this.zip = response.data.records;
                  this.showed = true;
                  break;
                case "html":
                  this.html = response.data.records;
                  this.showHtml = true;
                  break;
                case "iframe":
                  this.iframe = response.data.records;
                  this.showed = true;
                  break;
                case "own_video":
                  this.ownVideo = response.data.records;
                  this.showed = true;
                  break;
                default:
                  return response.data.records;
              }
            }
          } else {
            return response.data.records.content;
          }
          this.reloadAssets();
        })
        .catch(error => {
          if (error.response.status === 412) {
            this.$swal(this.callGetError("file_not_found"), "", "error");
          }
        });
    }
  },
  mounted() {
    if (this.isConstantsActive && !this.isProfileLoaded) {
      this.loadProfile();
    }
    if (this.$route.params.family) {
      this.filter(this.$route.params.family, "family");
    }
    if (this.$route.params.status) {
      this.checkRadioStatus = this.$route.params.status;
      this.filter(this.$route.params.status, "status", "or");
    }
    if (this.$route.params.priority) {
      this.checkRadioPriority = this.$route.params.priority;
      this.filter(this.$route.params.priority, "priority", "or");
    }
  },
  watch: {
    isConstantsActive(value) {
      if (value === true) this.loadProfile();
    },
    isProfileLoaded(value) {
      if (this.isConstantsActive && value === false) {
        this.loadProfile();
      }
    }
  }
};
</script>

<style lang="scss">
.nav.nav-pills.nav-secondary .show > .nav-link,
.nav.nav-pills.nav-secondary .nav-link:hover:not(.active) {
  color: #464e5f !important;
}
.btn.box-card {
  width: 30%;
  margin-right: 3%;
}

.table.b-table > thead > tr > [aria-sort].b-table-sort-icon-left,
.table.b-table > tfoot > tr > [aria-sort].b-table-sort-icon-left {
  padding-left: calc(0.75rem + 0.65em) !important;
}

.custom-pagination {
  li.page-item,
  li.page-item.disabled {
    margin: 0.25rem 0.5rem 0.25rem 0rem !important;
    button,
    span {
      outline: none !important;
      vertical-align: middle;
      -webkit-transition: color 0.15s ease, background-color 0.15s ease,
        border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
      transition: color 0.15s ease, background-color 0.15s ease,
        border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
      transition: color 0.15s ease, background-color 0.15s ease,
        border-color 0.15s ease, box-shadow 0.15s ease;
      transition: color 0.15s ease, background-color 0.15s ease,
        border-color 0.15s ease, box-shadow 0.15s ease,
        -webkit-box-shadow 0.15s ease;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      padding: 0;
      background-color: #f3f6f9;
      border-color: #f3f6f9;
      color: #7e8299;
      height: calc(1.35em + 1.1rem + 2px);
      width: calc(1.35em + 1.1rem + 2px);
      i {
        color: #7e8299;
      }
    }

    &.active > button,
    &.active > span {
      font-weight: bold;
      i {
        color: #282a35 !important;
      }
      color: #282a35 !important;
      background-color: #cccfdc !important;
      border-color: #e4e6ef !important;
    }
    &.disabled > button,
    &.disabled > span {
      i {
        color: #d9ddeb !important;
      }
      background-color: #f3f6f9 !important;
      border-color: #f3f6f9 !important;
    }
  }
}
</style>
